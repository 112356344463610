import React, { FC, useCallback, useEffect, useState } from 'react';
import { PageProps } from 'gatsby';
import DynamicComponent from '../new/components/DynamicComponent';
import StoryblokImageProvider from '../old/storyblok/asset-context';
import { StoryblokMetaProvider } from '../old/storyblok/meta-context';
import {
  CategoriesContextProvider,
  CategoryType,
} from '../old/storyblok/categories-context';
import { TagsContextProvider, TagType } from '../old/storyblok/tags-context';
import {
  AuthorsContextProvider,
  AuthorType,
} from '../old/storyblok/authors-context';
import { BlogPostsContextProvider } from '../old/storyblok/blogPosts-context';
import { CaseStudiesContextProvider } from '../old/storyblok/caseStudies-context';
import {
  StoryblokBlogPost,
  StoryblokCaseStudy,
  StoryblokReportPage,
} from '../types/blok.model';
import {
  WhitePaperFormDataContextProvider,
  WhitePaperFormDataType,
} from '../old/storyblok/hubspotFormsData-context';
import {
  ContactFormDataContextProvider,
  ContactFormDataType,
} from '../util/useContactFormData';
import { ReportsContextProvider } from '../old/storyblok/reports-context';
import { ViewportContextProvider } from '../util/useViewport';
import { Pagination } from '../types';

import {
  useStoryblokApi,
  StoryData,
  storyblokInit,
  apiPlugin,
} from 'gatsby-source-storyblok';
import { Helmet } from 'react-helmet';

storyblokInit({
  accessToken: process.env.GATSBY_STORYBLOK_ACCESS_TOKEN,
  use: [apiPlugin],
});

interface PageContextProps {
  slug: string;
  allCategories: CategoryType[];
  allTags: TagType[];
  blogPosts?: StoryblokBlogPost[];
  allBlogPosts?: StoryblokBlogPost[];
  reports?: StoryblokReportPage[];
  allReports?: StoryblokReportPage[];
  caseStudies?: StoryblokCaseStudy[];
  allCaseStudies?: StoryblokCaseStudy[];
  allAuthors?: AuthorType[];
  whitePaperFormData?: WhitePaperFormDataType[];
  contactFormData?: ContactFormDataType[];
  pagination?: Pagination;
}

const StoryBlokEditor: FC<PageProps<unknown, PageContextProps>> = ({
  location,
  pageContext,
}) => {
  const pagination = pageContext.pagination;
  const storyblokClient = useStoryblokApi();

  const [story, setStory] = useState<StoryData>();
  const path = new URLSearchParams(location.search).get('path');

  const getStory = useCallback(
    () =>
      storyblokClient
        .getStory(path || '/', {
          version: 'draft',
          resolve_links: 'story',
        })
        .then((storyResponse) => setStory(storyResponse.data.story)),
    [path, storyblokClient]
  );

  const startListen = useCallback(() => {
    if (!window.StoryblokBridge) {
      window.setTimeout(startListen, 500);
      return;
    }
    const { StoryblokBridge } = window;
    const bridge = new StoryblokBridge();
    bridge.on(['change', 'published'], () => {
      getStory();
    });
    bridge.on('input', (payload) => {
      if (!payload) {
        return;
      }
      setStory(payload.story);
    });
  }, [getStory]);

  useEffect(() => {
    getStory();
    startListen();
  }, [getStory, startListen]);

  if (!story) {
    return <></>;
  }

  const isLatestDesign = (story.content?.useLatestDesign as boolean) ?? false;

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <StoryblokImageProvider imageData={[]}>
        <StoryblokMetaProvider value={story}>
          <ViewportContextProvider>
            <ContactFormDataContextProvider data={pageContext.contactFormData}>
              <WhitePaperFormDataContextProvider
                data={pageContext.whitePaperFormData}
              >
                <CategoriesContextProvider data={pageContext.allCategories}>
                  <TagsContextProvider data={pageContext.allTags}>
                    <AuthorsContextProvider data={pageContext.allAuthors}>
                      <ReportsContextProvider
                        data={{
                          posts: pageContext.reports,
                          allPosts: pageContext.allReports,
                        }}
                        pagination={pagination}
                      >
                        <BlogPostsContextProvider
                          data={{
                            posts: pageContext.blogPosts,
                            allPosts: pageContext.allBlogPosts,
                          }}
                          pagination={pagination}
                        >
                          <CaseStudiesContextProvider
                            data={{
                              posts: pageContext.caseStudies,
                              allPosts: pageContext.allCaseStudies,
                            }}
                            pagination={pagination}
                          >
                            <DynamicComponent
                              blok={story.content}
                              story={story}
                              isLatestDesign={isLatestDesign}
                            />
                          </CaseStudiesContextProvider>
                        </BlogPostsContextProvider>
                      </ReportsContextProvider>
                    </AuthorsContextProvider>
                  </TagsContextProvider>
                </CategoriesContextProvider>
              </WhitePaperFormDataContextProvider>
            </ContactFormDataContextProvider>
          </ViewportContextProvider>
        </StoryblokMetaProvider>
      </StoryblokImageProvider>
    </>
  );
};

export default StoryBlokEditor;
